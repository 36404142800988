import {
  createRouter,
  createWebHashHistory
} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ParkView from '../views/ParkView.vue'
import DoorView from '../views/DoorView.vue'

const routes = [{
    path: '/',
    name: 'HomeView',
    component: HomeView
  },
  {
    path: '/ParkView',
    name: 'ParkView',
    component: ParkView
  },
  {
    path: '/DoorView',
    name: 'DoorView',
    component: DoorView
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
