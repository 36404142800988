export default {
  // 时间2方法
  FormatTime() { // 设置返回显示的日期时间格式
    const date = new Date();
    const year = this.formatTime(date.getFullYear());
    const month = this.formatTime(date.getMonth() + 1);
    const day = this.formatTime(date.getDate());
    const hour = this.formatTime(date.getHours());
    const minute = this.formatTime(date.getMinutes());
    const second = this.formatTime(date.getSeconds());
    const weekday = date.getDay();
    const weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
    const week = weeks[weekday];
    return {
      year: year,
      month: month,
      day: day,
      hour: hour,
      minute: minute,
      second: second,
      weekday: weekday,
      week: week
    }
    // `${year}年-${month}月${day}日 ${hour}:${minute}:${second} ${week}`
  },

  formatTime(n) {
    // 判断时间是否需要加0
    if (n < 10) {
      return '0' + n;
    } else {
      return n;
    }
  }
}
