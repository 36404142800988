import {
  createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import utils from './utils/utils'
import axios from 'axios';

axios.defaults.baseURL = '/api'



createApp(App).use(store).use(router).mount('#app')
