<template>
  <div class="list_demo">
    <div class="item_demo acea-row size18" v-for="(item, index) in listData" :key="index" >
      <div class="flex_demo acea-row row-column line1">
        <div class="content line1">{{ item.park_name }} {{ item.channel_name }}</div>
        <div class="acea-row row-between-wrapper font-colorba" style="margin-top: 8px;">
          <div class="acea-row row-middle">
            <p class="acea-row row-middle">
              <img src="@/assets/static/tiem_le.png" style="width: 6px;height: 16px;" />
              {{ item.offline_time }}
              <img src="@/assets/static/tiem_ri.png" style="width: 6px;height: 16px;" />
            </p>
            <p class="font-color41" style="padding:0 6px;">异常</p>
            <p class="acea-row row-middle" style="padding-left: 8px;" v-if="item.online_time">
              <img src="@/assets/static/tiem_le.png" style="width: 6px;height: 16px;" />
              {{ item.online_time }}
              <img src="@/assets/static/tiem_ri.png" style="width: 6px;height: 16px;" />
            </p>
            <p class="font-colorf4" style="padding:0 6px;" v-if="item.online_time">恢复</p>
          </div>
          <div class="status">
            <p class="font-color15" v-if="item.online_status == 2">【在线】</p>
            <p class="font-color41" v-if="item.online_status == 1">【离线】</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listData: {
      type: Array
    },
    data() {
      return {};
    }
  }
};
</script>

<style scoped lang="scss">
.list_demo {
  .item_demo {
    margin-top: 20px;
    cursor: pointer;
    .time {
      padding-right: 20px;
    }
    .flex_demo {
      flex: 1;
      display: inline-block;
    }

    .address {
      margin-top: 8px;
    }
  }
}
</style>
