<template>
  <router-view/>
</template>

<style>
  @import '/src/assets/base.css';

  body {
    overflow-y: hidden;
    overflow-x: hidden;
    margin: 0px;
  }

  div {
    box-sizing: border-box;
  }

  p {
    margin: 0;
  }

  img {
    vertical-align: middle;
  }
</style>
