<template>
  <div class="projector">
    <header class="header">
      <div class="bg">
        <div class="h_title f-between">全众云物业管理平台中心</div>
        <div class="time_demo">
          <FaTime />
          <!-- 全屏 -->
          <div class="shuax" @click="reload()">刷新</div>
          <div class="icon" @click="handleFullScreen()">
            <img src="@/assets/static/quan.png" style="width: 20px;height: 20px;" v-if="!$store.state.fullscreen" />
            <img src="@/assets/static/xiao.png" style="width: 20px;height: 20px;" v-else />
          </div>
        </div>
        <!-- 中间显示 -->
        <div class="main_demo">停车管理</div>
        <!-- 导航栏 -->
        <div class="le_demo" :class="barindex == barindex1 ? 'activity' + barindex1 : ''">
          <div class="item font-color09" @click="checkindex(index)" :class="barindex == index + 1 ? 'activity' : ''" v-for="(item, index) in barlist" :key="index">{{ item }}</div>
        </div>
      </div>
    </header>
    <section class="section">
      <!-- 合并左边中间盒子 -->
      <view class="merge_box">
        <view class="top_demo">
          <!-- 左边盒子 -->
          <div class="left-box">
            <div class="wrapper">
              <div class="monitor_demo">
                <div class="item">
                  <img class="icon" src="@/assets/static/cl.png" />
                  <div class="text_demo">
                    <div class="cantent size22">今日总流量</div>
                    <view class="sum size38">{{ parkInfo.total.flow_count }}</view>
                  </div>
                </div>
                <div class="item">
                  <img class="icon" src="@/assets/static/tc.png" />
                  <div class="text_demo">
                    <div class="cantent size22">今日入场流量</div>
                    <view class="sum size38">{{ parkInfo.total.flow_in_count }}</view>
                  </div>
                </div>
                <div class="item">
                  <img class="icon" src="@/assets/static/cd.png" />
                  <div class="text_demo">
                    <div class="cantent size22">今日出场流量</div>
                    <view class="sum size38">{{ parkInfo.total.flow_out_count }}</view>
                  </div>
                </div>
              </div>
            </div>
            <!-- 停车场车辆数据 -->
            <div class="wrapper">
              <div class="data_demo">
                <div class="cart_price">
                  <div class="cart_title size18">停车场车辆数据</div>
                  <div class="acea-row row-between-wrapper" style="height: 75%;">
                    <div class="acea-row row-center-wrapper" style="width: 30%;border-right: 2px dashed #fff;padding: 10px 0;box-sizing: border-box;">
                      <div class="price_demo">
                        <div class="item_demo" style="padding-bottom: 6px;">
                          <p class="font-color45" :class="fullscreen ? 'size28' : 'size24'">{{ parkIncome.total.toFixed(0) }}</p>
                          <p class="size14">套餐车总数</p>
                        </div>
                        <div class="item_demo" style="padding-bottom: 6px;">
                          <p class=" font-coloreb" :class="fullscreen ? 'size28' : 'size24'">{{ parkIncome.reduct_price.toFixed(0) }}</p>
                          <p class="size14">已过期</p>
                        </div>
                        <div class="item_demo" style="padding-bottom: 6px;">
                          <p class=" font-color09" :class="fullscreen ? 'size28' : 'size24'">{{ parkIncome.free_price.toFixed(0) }}</p>
                          <p class="size14">即将过期</p>
                        </div>
                        <div class="item_demo">
                          <p class="font-colorf4" :class="fullscreen ? 'size28' : 'size24'">{{ parkIncome.free_price.toFixed(0) }}</p>
                          <p class="size14">有效期内</p>
                        </div>
                      </div>
                    </div>
                    <div ref="bintu" style="width: 70%;height: 100%;color: #fff !important;"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 中间盒子 -->
          <div class="centre-box">
            <div class="wrapper">
              <div class="p_box acea-row row-center">
                <!-- 今日收款 -->
                <div class="pricebg_demo">
                  <div class="text font-colorea size18 bold">
                    <p>今日</p>
                    <p>收款</p>
                  </div>
                  <div class="sumlist acea-row">
                    <div class="acea-row item" v-for="(item, index) in income.DayList" :key="index">
                      <div class="num size38 font-color55 bg-color17" :style="{ transform: `translateY(-${item * 46}px)` }" v-if="index != income.DayList.length - 3">
                        <div class="li">0</div>
                        <div class="li">1</div>
                        <div class="li">2</div>
                        <div class="li">3</div>
                        <div class="li">4</div>
                        <div class="li">5</div>
                        <div class="li">6</div>
                        <div class="li">7</div>
                        <div class="li">8</div>
                        <div class="li">9</div>
                      </div>
                      <div class="size44 font-color55" v-else>.</div>
                    </div>
                  </div>
                  <div class="xiao size10 font-colorea">【交易{{ income.today.count_num || 0 }}笔】</div>
                </div>
                <!-- 昨日收款 -->
                <div class="month_demo acea-row row-middle">
                  <div class="text font-colorea size14"><p>昨日收款</p></div>
                  <div class="sumlist acea-row">
                    <div class="acea-row item" v-for="(item, index) in income.YDayList" :key="index">
                      <div class="num size26 font-colorfc bg-color17" :style="{ transform: `translateY(-${item * 29}px)` }" v-if="index != income.YDayList.length - 3">
                        <div class="li">0</div>
                        <div class="li">1</div>
                        <div class="li">2</div>
                        <div class="li">3</div>
                        <div class="li">4</div>
                        <div class="li">5</div>
                        <div class="li">6</div>
                        <div class="li">7</div>
                        <div class="li">8</div>
                        <div class="li">9</div>
                      </div>
                      <div class="size28 font-color22" v-else>.</div>
                    </div>
                  </div>
                  <div class="xiao size10 font-colorea">【交易{{ income.yesterday.count_num || 0 }}笔】</div>
                </div>
                <!-- 7日收款 -->
                <div class="month_demo acea-row row-middle">
                  <div class="text font-colorea size14"><p>七日收款</p></div>
                  <div class="sumlist acea-row">
                    <div class="acea-row item" v-for="(item, index) in income.WeekList" :key="index">
                      <div class="num size26 font-color45 bg-color17" :style="{ transform: `translateY(-${item * 29}px)` }" v-if="index != income.WeekList.length - 3">
                        <div class="li">0</div>
                        <div class="li">1</div>
                        <div class="li">2</div>
                        <div class="li">3</div>
                        <div class="li">4</div>
                        <div class="li">5</div>
                        <div class="li">6</div>
                        <div class="li">7</div>
                        <div class="li">8</div>
                        <div class="li">9</div>
                      </div>
                      <div class="size28 font-color45" v-else>.</div>
                    </div>
                  </div>
                  <div class="xiao size10 font-colorea">【交易{{ income.last7day.count_num || 0 }}笔】</div>
                </div>
                <!-- 本月收款 -->
                <div class="month_demo acea-row row-middle">
                  <div class="text font-colorea size14"><p>本月收款</p></div>
                  <div class="sumlist acea-row">
                    <div class="acea-row item" v-for="(item, index) in income.MonthList" :key="index">
                      <div class="num size26 font-color09 bg-color17" :style="{ transform: `translateY(-${item * 29}px)` }" v-if="index != income.MonthList.length - 3">
                        <div class="li">0</div>
                        <div class="li">1</div>
                        <div class="li">2</div>
                        <div class="li">3</div>
                        <div class="li">4</div>
                        <div class="li">5</div>
                        <div class="li">6</div>
                        <div class="li">7</div>
                        <div class="li">8</div>
                        <div class="li">9</div>
                      </div>
                      <div class="size28 font-color09" v-else>.</div>
                    </div>
                  </div>
                  <div class="xiao size10 font-colorea">【交易{{ income.thisMonth.count_num || 0 }}笔】</div>
                </div>
              </div>
            </div>
            <!-- 车辆排行榜 -->
            <div class="wrapper">
              <div class="data_demo">
                <div class="title_demo acea-row row-between-wrapper">
                  <div class="font-colorba" style="padding-bottom: 10px;">
                    <img src="@/assets/static/ph.png" style="width: 20px;height: 21px;padding-right: 4px;" />
                    车场排行榜（按日）
                  </div>
                  <div class="paih_btn">
                    <button class="button size16" :class="cheindex == 0 ? 'activity' : ''" @click="checkPh(0)">收入</button>
                    <button class="button size16" :class="cheindex == 1 ? 'activity' : ''" @click="checkPh(1)">优惠</button>
                    <button class="button size16 " :class="cheindex == 2 ? 'activity' : ''" @click="checkPh(2)">免费</button>
                  </div>
                </div>
                <div class="cheph"><div ref="ranking" style="width: 100%;" :style="'height: ' + parkInfo.arrList.length * 40 + 'px;'"></div></div>
              </div>
            </div>
          </div>
        </view>
        <!-- 左中下边盒子 -->
        <div class="statistics_box">
          <div class="data_demo">
            <div class="title_demo font-colorba">
              <div style="float: left;">
                <img src="@/assets/static/tjcl.png" style="width: 20px;height: 21px;padding-right: 4px;" />
                周时段车流量统计
              </div>
              <div class="statistics_btn">
                <button class="button size16" :class="in_out ? '' : 'activity'" @click="checkCarTj(0)">出场</button>
                <button class="button size16 " :class="in_out ? 'activity' : ''" @click="checkCarTj(1)">入场</button>
              </div>
            </div>
            <div ref="zhanbi" style="width: 100%;height: 100%;"></div>
          </div>
        </div>
      </view>
      <!-- 右边盒子 -->
      <div class="right-box">
        <!-- 饼图 -->
        <div class="wrapper">
          <div class="cart_price">
            <div class="cart_title size18">停车场今日收款</div>
            <div class="acea-row row-between-wrapper" style="height: 75%;">
              <div class="acea-row row-center-wrapper" style="width: 35%;border-right: 2px dashed #fff;padding: 10px 0;box-sizing: border-box;">
                <div class="price_demo">
                  <div class="item_demo" style="padding-bottom: 6px;">
                    <p class=" font-colorf4" :class="fullscreen ? 'size28' : 'size24'">{{ parkIncome.total.toFixed(2) }}</p>
                    <p class="size14">收费总额</p>
                  </div>
                  <div class="item_demo" style="padding-bottom: 6px;">
                    <p class=" font-coloreb" :class="fullscreen ? 'size28' : 'size24'">{{ parkIncome.reduct_price.toFixed(2) }}</p>
                    <p class="size14">优惠金额</p>
                  </div>
                  <div class="item_demo">
                    <p class=" font-colorfc" :class="fullscreen ? 'size28' : 'size24'">{{ parkIncome.free_price.toFixed(2) }}</p>
                    <p class="size14">免费金额</p>
                  </div>
                </div>
              </div>
              <div ref="bindu" style="width: 65%;height: 100%;color: #fff !important;"></div>
            </div>
          </div>
        </div>
        <!-- 设备网络预警 -->
        <div class="wrapper">
          <div class="data_demo">
            <div class="title_demo font-colorba">
              <img src="@/assets/static/wl.png" style="width: 20px;height: 20px;padding-right: 4px;" />
              设备网络预警
            </div>
            <AutoScrollList v-if="parkWarn!=null&&parkWarn.length"  :listData="parkWarn" :isPause="true" :demoType="'WarNing'"></AutoScrollList>
          </div>
        </div>
        <!-- 车辆放行处理 -->
        <div class="wrapper">
          <div class="data_demo">
            <div class="title_demo font-colorba">
              <img src="@/assets/static/cheche.png" style="width: 20px;height: 21px;padding-right: 4px;" />
              车辆放行处理
            </div>
            <AutoScrollList v-if="chelist!=null&&chelist.length" :listData="chelist" :isPause="true" :demoType="'ManageCart'"></AutoScrollList>
          </div>
        </div>
      </div>
    </section>
    <!-- 首次加载遮盖 -->
    <Load v-if="is_load"></Load>
    <UserDetail ref="UserDetail"></UserDetail>
  </div>
</template>

<script>
import FaTime from '@/components/Time.vue'; //时间组件
import UserDetail from '@/components/UserDetail.vue'; //账号审核组件
import AutoScrollList from '@/components/AutoScrollList.vue'; //滚动组件
import Load from '@/components/Load.vue'; //首次加载遮盖组件
import axios from 'axios';
import * as echarts from 'echarts';
export default {
  name: 'HomeView',
  components: {
    FaTime,
    AutoScrollList,
    Load,
    UserDetail
  },
  data() {
    return {
      changePieInterval: null, //今日收款饼图定时
      changePieInterval1: null, //车辆数据饼图定时
      barindex: 2,
      barindex1: 2,
      barlist: ['门禁管理', '停车管理', '物业管理'],
      parkIncome: {
        total: 0,
        reduct_price: 0,
        free_price: 0
      },
      income: {
        today: {
          //今天收款
          count_num: 0
        },
        yesterday: {
          //昨天收款
          count_num: 0
        },
        last7day: {
          //一周收款
          count_num: 0
        },
        thisMonth: {
          //本月收款
          count_num: 0
        }
      },
      is_load: true, //判断加载是否显示
      load: 0, //判断加载是否显示
      in_out: 0, //出入场判断
      in_weelList: [], //折线图数据
      out_weelList: [], //折线图数据
      weelList: [],
      cheindex: 0, //车辆排行bar
      parkInfo: {
        arrList: [],
        data: [], //排行榜
        total: {
          flow_count: 0,
          flow_in_count: 0,
          flow_out_count: 0
        }
      } ,//车流数据
      chelist: null, //车辆列表
      parkWarn: null, //设备网络预警
    };
  },
  created() {
    this.getData();
    setInterval(() => {
      this.getData();
    }, 60000);
  },
  mounted() {},
  beforeDestroy() {
    clearInterval(this.changePieInterval);
    clearInterval(this.changePieInterval1);
  },

  methods: {
    // 切换车辆排行
    checkPh(index) {
      this.cheindex = index;
      this.getRanking();
    },

    //切换周时统计出入场
    checkCarTj(index) {
      this.in_out = index;
      this.zhanbi();
    },

    // 获得数据
    getData() {
      this.load = 0;
      axios.get('bi/Index/parkTodo').then(res => {
        this.chelist = res.data;
        this.load++;
        this.changeload();
      });
      axios.get('bi/index/park').then(res => {
        this.in_weelList = [];
        this.out_weelList = [];
        this.parkInfo.arrList = [];
        this.parkInfo = res.data;

        //车场排行数组处理
        let arrList = [];
        for (let key in res.data.data) {
          arrList.push(res.data.data[key]);
        }
        this.parkInfo.arrList = arrList;

        //周时段车流量统计
        let rqList = [];
        let weelList = [];
        let dayList = [];
        //集合一周日期
        res.data.flow.forEach(item => {
          if (rqList.indexOf(item.date) == -1) {
            rqList.push(item.date);
          }
        });

        rqList.forEach(item => {
          //循环一周日期
          res.data.flow.forEach(itemn => {
            //循环某日的二十四小时
            if (item == itemn.date) {
              //集合某天二十四小时
              dayList.push(itemn);
            }
          });
          weelList.unshift(dayList.sort((a, b) => a.hour - b.hour)); //推行某天的时间
          dayList = [];
        });

        weelList.forEach(item => {
          let in_dx = {
            weel: new Date(item[0].date).getDay(),
            name: item[0].date,
            type: 'line',
            data: []
          };
          let out_dx = {
            weel: new Date(item[0].date).getDay(),
            name: item[0].date,
            type: 'line',
            data: []
          };
          item.forEach(itemn => {
            in_dx.data.push(itemn.in_count || 0);
            out_dx.data.push(itemn.out_count || 0);
          });
          this.in_weelList.push(in_dx);
          this.out_weelList.push(out_dx);
          in_dx = {};
          out_dx = {};
        });

        this.out_weelList.push(
          {
            name: '平日日均出率',
            type: 'line',
            data: []
          },
          {
            name: '周末日均出率',
            type: 'line',
            data: []
          }
        );
        this.in_weelList.push(
          {
            name: '平日日均入率',
            type: 'line',
            data: []
          },
          {
            name: '周末日均入率',
            type: 'line',
            data: []
          }
        );

        let in_data = [...this.in_weelList, ...[]];
        let out_data = [...this.out_weelList, ...[]];
        in_data = in_data.sort((a, b) => a.weel - b.weel);
        out_data = out_data.sort((a, b) => a.weel - b.weel);
        for (let i = 0; i < 24; i++) {
          this.in_weelList[7].data.push(
            (Number(in_data[1].data[i]) + Number(in_data[2].data[i]) + Number(in_data[3].data[i]) + Number(in_data[4].data[i]) + Number(in_data[5].data[i])) / 5
          );
          this.in_weelList[8].data.push((Number(in_data[0].data[i]) + Number(in_data[6].data[i])) / 2);
          this.out_weelList[7].data.push(
            (Number(out_data[1].data[i]) + Number(out_data[2].data[i]) + Number(out_data[3].data[i]) + Number(out_data[4].data[i]) + Number(out_data[5].data[i])) / 5
          );
          this.out_weelList[8].data.push((Number(out_data[0].data[i]) + Number(out_data[6].data[i])) / 2);
        }
        this.zhanbi();
        this.load++;
        this.changeload();
        setTimeout(() => {
          this.getRanking();
        }, 0);
      });
      axios.get('/bi').then(res => {
        let income = res.data.income;
        this.dataInfo = res.data;
        income.DayList = income.today.sum_hjje.split(''); //今天收款
        income.WeekList = this.chuliArr(income.last7day.sum_hjje); //一周收款
        income.YDayList = this.chuliArr(income.yesterday.sum_hjje); //昨天收款
        income.MonthList = this.chuliArr(income.thisMonth.sum_hjje); //本月收款
        this.income = income; //中间金额
        this.parkIncome = res.data.parkIncome; //饼图部分
        this.parkWarn = res.data.parkWarn; //设备网络预警
        clearInterval(this.changePieInterval);
        clearInterval(this.changePieInterval1);
        this.getEchartData();
        this.getEchartData1();
        this.load++;
        this.changeload();
      });
    },
    //
    changeload() {
      if (this.load == 3) {
        this.is_load = false;
      }
    },

    //字符串转数组
    chuliArr(arrList) {
      let list = arrList.split('');
      if (list.length != 11) {
        for (let i = list.length; i < 11; i++) {
          list.unshift('');
        }
      }
      return list;
    },

    // 刷新
    reload() {
      this.getData();
    },

    // 切换导航
    checkindex(index) {
      this.barindex = index + 1;
      this.barindex1 = index + 1;
      this.bartext = this.barlist[index];
      if (index == 2) {
        this.$router.push({
          path: '/'
        });
      } else if (index == 0) {
        this.$router.push({
          path: '/DoorView'
        });
      }
    },

    //柱状排名
    getRanking() {
      let nameList = [];
      let nameList1 = [];
      let arrList = [];
      let colorList = [];
      this.parkInfo.arrList.forEach(item => {
        if (this.cheindex == 0) {
          arrList.push(Number(item.total));
        } else if (this.cheindex == 1) {
          arrList.push(Number(item.free_price));
        } else {
          arrList.push(Number(item.reduct_price));
        }
        nameList.push(item.name);
        colorList.push('#5c84ba');
      });
      var ROOT_PATH = 'https://echarts.apache.org/examples';
      var chartDom = this.$refs.ranking;
      var myChart = echarts.init(chartDom);
      var option;
      const dimension = 0;

      option = {
        color: '#5c84ba',
        textStyle: {
          fontSize: 12,
          color: '#5c84ba' //底部左边字体颜色
        },
        tooltip: {
          type: 'value',
          name: '占比',
          textStyle: {
            fontSize: 12,
            color: '#5c84ba' //字体颜色
          }
        },

        grid: {
          left: '0%',
          right: '14%',
          bottom: '1%',
          top: '0.5%',
          containLabel: true
        },
        xAxis: {
          max: 'dataMax'
        },
        yAxis: [
          {
            type: 'category',
            data: nameList,
            inverse: true,
            animationDuration: 300,
            animationDurationUpdate: 300,
            max: arrList.length, // 最大排序数量
            axisLabel: {
              //关键代码
              show: true,
              interval: 0, //强制所有标签显示
              formatter: function(params) {
                if (params.length > 5) return params.substring(0, 5) + '...';
                else return params;
              }
            },
            triggerEvent: true
          }
        ],
        series: [
          {
            realtimeSort: true,
            seriesLayoutBy: 'column',
            type: 'bar',
            data: arrList,
            itemStyle: {
              barBorderRadius: [50, 0, 0, 50], // 统一设置四个角的圆角大小
              normal: {
                //这里是重点
                color: function(params) {
                  //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                  return colorList[params.dataIndex];
                }
              }
            },

            encode: {
              x: dimension,
              y: 3
            },
            label: {
              normal: {
                show: true,
                position: 'right',
                color: '#fff',
                formatter: '{c}元'
              }
            }
          }
        ],
        legend: {
          show: true,
          textStyle: {
            //图例文字的样式
            color: '#fff',
            fontSize: 12
          }
        },
        animationDuration: 0,
        animationDurationUpdate: 3000,
        animationEasing: 'linear',
        animationEasingUpdate: 'linear'
      };

      myChart.setOption(option);
      window.addEventListener('resize', function() {
        myChart.resize();
      });
    },

    //折线图
    zhanbi() {
      let that = this;
      const chartDom = this.$refs.zhanbi;
      const myChart = echarts.init(chartDom, '');
      const option = {
        color: ['#fcfb00', '#9FE6B8', '#67E0E3', '#E62558', '#8378EA', '#67E452', '#E690D1', '#96BFFF', '#6b5668'], //E690D1//6b5668
        tooltip: {
          trigger: 'axis',
          extraCssText: 'width:180px;height:225px;',
          textStyle: {
            fontSize: 0.01
          },
          position: function(point, params, dom, rect, size) {
            // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
            // 提示框位置
            var x = 0; // x坐标位置
            var y = 0; // y坐标位置
            // 当前鼠标位置
            var pointX = point[0];
            var pointY = point[1];
            // 提示框大小
            var boxWidth = size.contentSize[0];
            var boxHeight = size.contentSize[1];

            // boxWidth > pointX 说明鼠标左边放不下提示框
            if (boxWidth > pointX) {
              x = 300;
            } else {
              // 左边放的下
              x = pointX - boxWidth;
            }
            // boxHeight > pointY 说明鼠标上边放不下提示框
            if (boxHeight > pointY) {
              y = -28;
            } else {
              // 上边放得下
              y = pointY - boxHeight;
            }
            return [x, y];
          }
        },
        legend: {
          textStyle: {
            //图例文字的样式
            color: '#fff',
            fontSize: 12
          },
          bottom: '1%',
          data: this.in_out ? this.in_weelList.name : this.out_weelList.name
        },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '12%',
          top: '17%',
          containLabel: true
        },
        xAxis: {
          axisLabel: {
            show: true,
            textStyle: {
              color: '#c3dbff'
            }
          },
          type: 'category',
          data: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
        },
        yAxis: {
          axisLabel: {
            show: true,
            textStyle: {
              color: '#c3dbff'
            }
          },
          type: 'value',
          data: ['0', '50', '100', '150', '200', '250', '300', '350']
        },
        series: this.in_out ? this.in_weelList : this.out_weelList
      };
      myChart.setOption(option);
      window.addEventListener('resize', function() {
        myChart.resize();
      });
    },

    //停车场车辆数据饼图
    getEchartData1() {
      const chart = this.$refs.bintu;
      const myChart = echarts.init(chart);
      const option = {
        color: ['#6924FE', '#e19c09', '#2fe7f4'],
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: 'bottom',
          textStyle: {
            //图例文字的样式
            color: '#fff',
            fontSize: 12
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: true,
            center: ['50%', '40%'],
            label: {
              show: false,
              position: 'center',
              color: '#fff'
            },
            emphasis: {
              //设置高亮时显示标签
              show: true,
              textStyle: {
                fontSize: '15',
                fontWeight: 'bold',
                color: '#fff'
              },
              label: {
                show: true,
                fontSize: '14',
                fontWeight: 'bold',
                color: '#fff'
              }
            },
            labelLine: {
              show: false,
              color: '#fff'
            },
            data: [
              {
                value: this.parkIncome.temp_total_price,
                name: '已过期' + this.baifbFu(this.parkIncome.temp_total_price, this.parkIncome.total) + '%'
              },
              {
                value: this.parkIncome.vip_total_price,
                name: '即将过期' + this.baifbFu(this.parkIncome.vip_total_price, this.parkIncome.total) + '%'
              },
              {
                value: this.parkIncome.merchant_total_price,
                name: '有效期内' + this.baifbFu(this.parkIncome.merchant_total_price, this.parkIncome.total) + '%'
              }
            ]
          }
        ]
      };
      let currentIndex = -1; //当前高亮图形在饼图数据中的下标
      this.changePieInterval1 = setInterval(selectPie, 4000); //设置自动切换高亮图形的定时器

      function highlightPie() {
        //取消所以高亮并高亮当前图形
        //遍历饼图数据，取消所以图形的高亮效果
        for (var idx in option.series[0].data)
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: idx
          });
        //高亮当前图形
        myChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: currentIndex
        });
      }
      myChart.on('mouseover', params => {
        //用户鼠标悬浮到某一图形时，停止自动切换并高亮鼠标悬浮的图形
        clearInterval(this.changePieInterval1);
        currentIndex = params.dataIndex;
        highlightPie();
      });
      myChart.on('mouseout', params => {
        //用户鼠标移出时，重新开始自动切换
        if (this.changePieInterval1) clearInterval(this.changePieInterval1);
        this.changePieInterval1 = setInterval(selectPie, 4000);
      });

      function selectPie() {
        //高亮效果切换到下一个图形
        var dataLen = option.series[0].data.length;
        currentIndex = (currentIndex + 1) % dataLen;
        highlightPie();
      }
      myChart.setOption(option);
      window.addEventListener('resize', function() {
        myChart.resize();
      });
    },

    //今日收款饼图
    getEchartData() {
      const chart = this.$refs.bindu;
      const myChart = echarts.init(chart);
      const option = {
        color: ['#e19c09', '#2fe7f4', '#6924FE'],
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: 'bottom',
          textStyle: {
            //图例文字的样式
            color: '#fff',
            fontSize: 12
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: true,
            center: ['50%', '40%'],
            label: {
              show: false,
              position: 'center',
              color: '#fff'
            },
            emphasis: {
              //设置高亮时显示标签
              show: true,
              textStyle: {
                fontSize: '15',
                fontWeight: 'bold',
                color: '#fff'
              },
              label: {
                show: true,
                fontSize: '14',
                fontWeight: 'bold',
                color: '#fff'
              }
            },
            labelLine: {
              show: false,
              color: '#fff'
            },
            data: [
              {
                value: this.parkIncome.temp_total_price,
                name: '临时缴费' + this.baifbFu(this.parkIncome.temp_total_price, this.parkIncome.total) + '%'
              },
              {
                value: this.parkIncome.vip_total_price,
                name: '月卡充值' + this.baifbFu(this.parkIncome.vip_total_price, this.parkIncome.total) + '%'
              },
              {
                value: this.parkIncome.merchant_total_price,
                name: '商家充值' + this.baifbFu(this.parkIncome.merchant_total_price, this.parkIncome.total) + '%'
              }
            ]
          }
        ]
      };
      let currentIndex = -1; //当前高亮图形在饼图数据中的下标
      this.changePieInterval = setInterval(selectPie, 4000); //设置自动切换高亮图形的定时器

      function highlightPie() {
        //取消所以高亮并高亮当前图形
        //遍历饼图数据，取消所以图形的高亮效果
        for (var idx in option.series[0].data)
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: idx
          });
        //高亮当前图形
        myChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: currentIndex
        });
      }
      myChart.on('mouseover', params => {
        //用户鼠标悬浮到某一图形时，停止自动切换并高亮鼠标悬浮的图形
        clearInterval(this.changePieInterval);
        currentIndex = params.dataIndex;
        highlightPie();
      });
      myChart.on('mouseout', params => {
        //用户鼠标移出时，重新开始自动切换
        if (this.changePieInterval) clearInterval(this.changePieInterval);
        this.changePieInterval = setInterval(selectPie, 4000);
      });

      function selectPie() {
        //高亮效果切换到下一个图形
        var dataLen = option.series[0].data.length;
        currentIndex = (currentIndex + 1) % dataLen;
        highlightPie();
      }
      myChart.setOption(option);
      window.addEventListener('resize', function() {
        myChart.resize();
      });
    },

    // 百分比
    baifbFu(val1, val2) {
      return Math.round((val1 / val2) * 100);
    },

    // 全屏事件
    handleFullScreen() {
      let element = document.documentElement;
      // 判断是否已经是全屏
      // 如果是全屏，退出
      if (this.$store.state.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        // 否则，进入全屏
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      // 改变当前全屏状态
      this.$store.commit('onfullscreen');
    }
  }
};
</script>

<style lang="scss" scoped>
.projector {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: #030617;
  // background-image: url('@/assets/static/dbj.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center top;
  overflow: hidden;
  cursor: default;

  .header {
    position: relative;
    z-index: 9;
    height: 130px;
    width: 100vw;

    .bg {
      height: 100px;
      width: 100vw;
      background-image: url('@/assets/static/toubu.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center top;
      overflow: hidden;
    }

    .h_title {
      margin-top: 12px;
      text-align: center;
      font-size: calc(100vw * 32 / 1920);
      color: #fff;
    }

    .le_demo {
      position: absolute;
      top: 22px;
      left: 10px;
      height: 33px;
      width: 456px;
      background-image: url('@/assets/static/activity3.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      overflow: hidden;
      display: flex;
      padding: 0 20px;

      .item {
        flex: 1;
        font-size: calc(100vw * 18 / 1920);
        display: flex;
        justify-content: center;
        padding-top: 5px;
        cursor: pointer;
      }

      .activity {
        color: #fff;
        height: 31px;
        font-weight: bold;
      }
    }

    .activity1 {
      background-image: url('@/assets/static/activity1.png');
    }

    .activity2 {
      background-image: url('@/assets/static/activity2.png');
    }

    .activity3 {
      background-image: url('@/assets/static/activity3.png');
    }

    .main_demo {
      position: absolute;
      top: 86px;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 50px;
      width: 172px;
      background-image: url('@/assets/static/main.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center top;
      overflow: hidden;
      font-size: calc(100vw * 18 / 1920);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .time_demo {
      position: absolute;
      right: 10px;
      top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;

      .shuax {
        padding-left: 10px;
        cursor: pointer;
      }

      .icon {
        cursor: pointer;
        padding-left: 10px;

        img {
          margin: 0;
          display: inline-block;
        }
      }
    }
  }

  .section {
    position: fixed;
    top: 0;
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;

    .merge_box {
      display: flex;
      flex-direction: column;
      flex: 2;

      .top_demo {
        display: flex;
        height: 66.6%;

        .left-box,
        .centre-box {
          width: 50%;
          height: 100%;

          .wrapper {
            height: 50%;
          }
        }
      }

      .statistics_box {
        height: 33.3%;
        width: 100%;
        padding: 20px;

        .data_demo {
          height: 100%;
          padding: 20px 12px;
          padding-bottom: 0px;
          background-image: url('@/assets/static/cbeij.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          overflow: hidden;

          .title_demo {
            align-items: center;
            vertical-align: middle;
            position: relative;
          }
          .statistics_btn {
            position: absolute;
            right: 0;
            z-index: 9;
          }
          .button {
            background-color: transparent;
            color: #fff;
            border: 1px solid #5c84ba;
            border-radius: 2px;
            padding: 2px;
            width: 60px;
            margin-left: 10px;
          }
          .activity {
            color: #fff;
            background-color: #5c84ba;
          }
        }
      }
    }

    .right-box {
      flex: 1;
    }

    .left-box,
    .right-box {
      .wrapper {
        padding: 20px;
        height: 33.3%;
        color: #fff;

        .monitor_demo {
          height: 100%;
          background-image: url('@/assets/static/ribg.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          overflow: hidden;
          display: flex;

          .item {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .icon {
              width: 60px;
              height: 60px;
            }

            .text_demo {
              margin-top: 20px;

              .sum {
                margin-top: 14px;
              }
            }
          }
        }

        .user_demo {
          position: relative;
          height: 100%;
          background-image: url('@/assets/static/lv.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          overflow: hidden;
          color: #fff;
        }

        .data_demo {
          padding: 20px 12px;
          height: 100%;
          background-image: url('@/assets/static/xiaobj.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          overflow: hidden;
        }
      }

      .wrapper:nth-child(1) {
        padding-top: 72px;
      }
    }

    .centre-box {
      .wrapper {
        height: 33.3%;
        padding: 20px;
        color: #fff;

        .data_demo {
          padding: 20px 12px;
          height: 100%;
          background-image: url('@/assets/static/xiaobj.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          overflow: hidden;

          .cheph {
            overflow: auto;
            overflow-y: scroll;
            height: 90%;
          }
          .cheph::-webkit-scrollbar {
            display: none;
          }

          .title_demo {
            position: relative;
            display: flex;
            align-items: center;
            vertical-align: middle;

            .paih_btn {
              position: absolute;
              right: 0;
              z-index: 9;
            }
            .button {
              background-color: transparent;
              color: #fff;
              border: 1px solid #5c84ba;
              border-radius: 2px;
              padding: 2px;
              width: 60px;
              margin-left: 10px;
            }
            .activity {
              color: #fff;
              background-color: #5c84ba;
            }
          }

          .list_demo {
            .item_demo {
              margin-top: 20px;

              .time {
                padding-right: 20px;
              }

              .flex_demo {
                flex: 1;
                display: inline-block;
              }

              .address {
                margin-top: 8px;
              }
            }
          }
        }
      }

      .wrapper:nth-child(1) {
        .p_box {
          padding-top: 100px;
          width: 100%;
          justify-content: center;
        }

        .pricebg_demo {
          position: relative;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: center;
          width: 95%;
          height: 65px;
          background-image: url('@/assets/static/pricebg.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-bottom: 10px;

          .text {
            padding: 0 8px 0 20px;
            letter-spacing: 10px;
          }

          .sumlist {
            .item {
              height: 46px;
              margin-right: 6px;
              overflow: hidden;

              .num {
                display: inline-block;
                height: 46px;
                transition: all 3s ease-out;

                .li {
                  line-height: 46px;
                  height: 46px;
                  background-color: #030617;
                  padding: 0 3px;
                }
              }

              .num:nth-child(8) {
                margin-right: 0;
              }
            }
          }
        }

        .month_demo {
          position: relative;
          padding-right: 12px;
          margin-bottom: 10px;

          .text {
            width: 60px;
          }

          .sumlist {
            // flex: 1;
            .item {
              height: 29px;
              margin-right: 6px;
              overflow: hidden;

              .num {
                display: inline-block;
                transition: all 3s ease-out;

                .li {
                  line-height: 29px;
                  height: 29px;
                  padding: 0 3px;
                  border: 1px solid #122344;
                }
              }

              .num:nth-child(8) {
                margin-right: 0;
              }
            }
          }
        }

        .xiao {
          width: 110px;
        }
      }
    }

    .right-box {
      .wrapper {
        .cart_price {
          height: 100%;
          background-image: url('@/assets/static/ribg.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          overflow: hidden;

          .cart_title {
            padding: 12px 0 12px;
            text-align: center;
          }

          .price_demo {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    .left-box {
      .wrapper {
        .data_demo {
          .cart_price {
            height: 100%;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            overflow: hidden;

            .cart_title {
              padding: 0px;
              text-align: center;
            }

            .price_demo {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
    }
  }
}
</style>
