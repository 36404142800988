<template>
  <div ref="scrollMain" class="auto-scroll-list-main" @click="autoScrollClick($event)" :key="keyValue"
    @mouseover="mEnter" @mouseleave="mLeave">
    <div ref="scrollItemBox" class="seamless-warp-box">
      <UserIDlist :listData="listData" v-if="demoType == 'UserIDlist'"></UserIDlist>
      <FeedBack :listData="listData" v-if="demoType == 'FeedBack'"></FeedBack>
      <MainTain :listData="listData" v-if="demoType == 'MainTain'"></MainTain>
      <WarNing :listData="listData" v-if="demoType == 'WarNing'"></WarNing>
      <ManageCart :listData="listData" v-if="demoType == 'ManageCart'"></ManageCart>
    </div>
    <div v-html="copyHtml" class="seamless-warp-box" v-if="show"></div>
  </div>
</template>
<script>
  import UserIDlist from '@/components/AutoScrollList_children/UserIDlist'; //用户审核
  import FeedBack from '@/components/AutoScrollList_children/FeedBack'; //投诉建议
  import MainTain from '@/components/AutoScrollList_children/MainTain';
  import WarNing from '@/components/AutoScrollList_children/WarNing';
  import ManageCart from '@/components/AutoScrollList_children/ManageCart';
  export default {
    name: 'AutoScrollList',
    components: {
      UserIDlist,
      FeedBack,
      MainTain,
      WarNing,
      ManageCart
    },
    props: {
      listData: {
        type: Array,
        default () {
          return null;
        }
      },
      isPause: {
        type: Boolean,
        default: false
      },
      keyValue: {
        type: [String, Number],
        default: ''
      },
      demoType: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        scrollTop: 0, //列表滚动高度
        speed: 70, //滚动的速度
        copyHtml: null,
        scrollInterval: null,
        show: true,
        time: null
      };
    },
    watch: {
      isPause(newValue, _) {
        if (newValue) {
          this.mEnter();
        } else {
          this.mLeave();
        }
      },
      // listData: {
      //   deep: false,
      //   immediate: false,
      //   handler(newValue, _) {
      //     this.mEnter();
      //     this.initScroll();
      //   }
      // }
    },
    mounted() {
      // 如果列表数据是异步获取的，记得初始化在获取数据后再调用
      this.$nextTick(() => {
        this.mEnter();
        this.initScroll();
      })
    },
    beforeUpdate() {},
    methods: {
      initScroll() {
        this.$nextTick(() => {
          this.copyHtml = this.$refs.scrollItemBox.innerHTML;
          this.startScroll();
        });
      },
      // 鼠标移入停止滚动
      mEnter() {
        this.show = false;
        if (this.scrollInterval) {
          clearInterval(this.scrollInterval);
          this.scrollInterval = null;
        }
      },
      // 鼠标移出继续滚动
      mLeave() {
        this.startScroll();
        this.show = true;
      },
      // 开始滚动
      startScroll() {
        //在当前位置开始滚动
        this.scrollTop = this.$refs.scrollMain.scrollTop;
        this.scrollInterval = setInterval(this.scroll, this.speed);
      },

      // 滚动处理方法
      scroll() {
        let scrollItemBox = 0
        this.scrollTop = Number(this.scrollTop) + 1;
        if (this.$refs.scrollMain) {
          this.$refs.scrollMain.scrollTop = Number(this.scrollTop) ?? 0;
          scrollItemBox = this.$refs.scrollMain.scrollHeight / 2;
        }
        // 获取需要滚动的盒子的高度
        // 当判断滚动的高度大于等于盒子高度时，从头开始滚动
        if (this.scrollTop >= scrollItemBox) {
          this.scrollTop = 0;
        }
      },

      autoScrollClick(e) {
        let index = e.target.dataset.index;
        if (index === undefined) {
          return;
        }
        //默认是number 自己改
        this.$emit('autoScrollClick', Number(index));
      }
    },
    destroyed() {
      this.mEnter();
    }
  };
</script>

<style lang="scss" scoped>
  .auto-scroll-list-main {
    overflow: auto;
    overflow-y: scroll;
    margin-top: 12px;
    height: 90%;
  }

  .auto-scroll-list-main::-webkit-scrollbar {
    display: none;
  }

  .seamless-warp-box {
    width: 100%;
  }
</style>