<template><!-- 投诉 -->
  <div class="list_demo">
    <div class="item_demo acea-row  size18" v-for="(item, index) in listData" :key="index">
      <div class="time acea-row row-top font-colorba">
        <p class="acea-row row-middle">
          <img src="@/assets/static/tiem_le.png" style="width: 6px;height: 16px;" />
          {{ item.strcjsj.slice(0,10)}}
          <img src="@/assets/static/tiem_ri.png" style="width: 6px;height: 16px;" />
        </p>
      </div>
      <div class="type font-colorf4">【{{ item.tstitle}}】</div>
      <div class="flex_demo acea-row row-column line1">
        <div class="content line1">{{ item.tscontent }}</div>
        <div class="address size14 font-colorba line1">{{ item.cname }}</div>
      </div>
      <div class="status">
        <p class="font-color41">【待处理】</p>
        <!-- <p class="font-color41" v-if="item.tstype == 1">【未回】</p> -->
        <!-- <p class="font-color09" v-if="item.tstype == 2">【已读】</p> -->
        <!-- <p class="font-colorf4" v-if="item.tstype == 3">【已回】</p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listData: {
      type: Array
    },
    data() {
      return {};
    }
  }
};
</script>

<style scoped lang="scss">
.list_demo {
  .item_demo {
    margin-top: 20px;
    cursor: pointer;
    overflow: hidden;
    box-sizing: border-box;
    .time {
      padding-right: 20px;
    }
    .flex_demo {
      flex: 1;
      display: inline-block;
      width: 200px;
      overflow: hidden;
    }

    .address {
      margin-top: 8px;
    }
  }
}
</style>
