<template>
  <div class="user_detail" v-if="is_close">
      <div class="mask" ></div >
      <div class="wrapper bg-color54">
        <header class="theme_demo font-colorba size22 acea-row row-center-wrapper">账号审核</header>
        <section class="section_demo">
          <div class="item">
            <div class="le_demo co">用户名：</div>
            <div class="ri_demo">googie</div>
          </div>
          <div class="item ">
            <div class="le_demo">业主姓名：</div>
            <div class="ri_demo">谢明、陈波</div>
          </div>
          <div class="item">
            <div class="le_demo">用户类型：</div>
            <div class="ri_demo">业主</div>
          </div>
          <div class="item">
            <div class="le_demo">联系电话：</div>
            <div class="ri_demo">15625031312</div>
          </div>
          <div class="item">
            <div class="le_demo">注册时间：</div>
            <div class="ri_demo">2022-10-28</div>
          </div>
          <div class="item">
            <div class="le_demo">地址：</div>
            <div class="ri_demo">家成公寓-3期2幢-3期2幢3座-LQ62-3-0301</div>
          </div>
        </section>
        <footer class="footer acea-row row-right">
          <div class="item_btn acea-row row-center-wrapper" :class="{ activity: btn[0].is }" @mousedown="down(0)" @mouseup="up(0)">回复</div>
          <div class="item_btn acea-row row-center-wrapper" :class="{ activity: btn[1].is }" @mousedown="down(1)" @mouseup="up(1)">拒绝</div>
          <div class="item_btn acea-row row-center-wrapper" :class="{ activity: btn[2].is }" @mousedown="down(2)" @mouseup="up(2)">审核</div>
        </footer>
        <div class="close" @click="close"><img src="@/assets/static/close.png" alt="" /></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_close:false,
      btn: [{ is: false }, { is: false }, { is: false }]
    };
  },
  methods: {
    down(index) {
      this.btn[index].is = !this.btn[index].is;
    },
    up(index) {
      setTimeout(() => {
        this.btn[index].is = !this.btn[index].is;
      }, 300);
    },

    close(){
      this.is_close=false
    }
  }
};
</script>

<style lang="scss" scoped>
.mask {
  z-index: 0;
}
.user_detail {
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
}

.wrapper {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  // background-image: url('@/assets/static/xiaobj.png');
  // background-size: 100% 100%;
  // background-repeat: no-repeat;
  // background-position: center top;
  overflow: hidden;

  .theme_demo {
    height: 50px;
  }
  .section_demo {
    padding: 0 20px;
    .item {
      display: flex;
      padding-bottom: 14px;
      .le_demo {
        color: #aaa;
        margin-right: 8px;
      }
    }
  }
  .footer {
    margin-top: 10px;
    border-top: 1px solid #aaa;
    padding: 20px 20px;
    .item_btn {
      width: 100px;
      padding: 5px 0;
      // background-color: #5c84ba;
      border-radius: 2px;
      margin-left: 20px;
      border: 1px solid #5c84ba;
      // background-image: linear-gradient(#2fe7f4, #fff);
    }

    .activity {
      background-color: #5c84ba;
    }
  }

  .close {
    position: absolute;
    right: 20px;
    top: 10px;
    width: 20px;
    height: 20px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
