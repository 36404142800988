<template>
  <div class="projector">
    <header class="header">
      <div class="bg">
        <div class="h_title f-between">全众云门禁管理平台中心</div>
        <div class="time_demo">
          <FaTime />
          <!-- 全屏 -->
          <div class="shuax" @click="reload()">刷新</div>
          <div class="icon" @click="handleFullScreen()">
            <img src="@/assets/static/quan.png" style="width: 20px;height: 20px;" v-if="!fullscreen" />
            <img src="@/assets/static/xiao.png" style="width: 20px;height: 20px;" v-else />
          </div>
        </div>
        <!-- 中间显示 -->
        <div class="main_demo">门禁管理</div>
        <!-- 导航栏 -->
        <div class="le_demo" :class="barindex == barindex1 ? 'activity' + barindex1 : ''">
          <div class="item font-color09" @click="checkindex(index)" :class="barindex == index + 1 ? 'activity' : ''" v-for="(item, index) in barlist" :key="index">{{ item }}</div>
        </div>
      </div>
    </header>
    <section class="section">
      <!-- 左边盒子 -->
      <div class="left-box">
        <div class="wrapper">
          <!-- 用户率 -->
          <div class="user_demo">
            <div class="centre_demo">
              <p class="num_demo size40">{{resident.total}}</p>
              <p class="text_demo size20">总户数</p>
            </div>
            <div class="item1">
              <p class="num_demo size40">{{resident.bind}}</p>
              <p class="text_demo size20">绑定量</p>
            </div>
            <div class="item2">
              <p class="num_demo size40">{{baifbFu(resident.bind,resident.total)}}%</p>
              <p class="text_demo size20">绑定率</p>
            </div>
            <div class="item3">
              <p class="num_demo size40">{{resident.today}}</p>
              <p class="text_demo size20">今日新增</p>
            </div>
            <div class="item4">
              <p class="num_demo size40">{{resident.thisMonth}}</p>
              <p class="text_demo size20">本月新增</p>
            </div>
          </div>
        </div>
        <!-- 物业维修 -->
        <div class="wrapper">
          <div class="data_demo">
            <div class="title_demo font-colorba">
              <img src="@/assets/static/iconche.png" style="width: 20px;height: 18px;padding-right: 4px;" />
              物业维修
            </div>
            <AutoScrollList :listData="repair" :isPause="true" :demoType="'MainTain'"></AutoScrollList>
          </div>
        </div>
        <!-- 投诉建议 -->
        <div class="wrapper">
          <div class="data_demo">
            <div class="title_demo font-colorba">
              <img src="@/assets/static/toushu.png" style="width: 20px;height: 18px;padding-right: 4px;" />
              投诉建议
            </div>
            <AutoScrollList :listData="suggest" :isPause="true" :demoType="'FeedBack'"></AutoScrollList>
          </div>
        </div>
      </div>
      <!-- 中间盒子 -->
      <div class="centre-box">
        <div class="wrapper">
          <div class="acea-row row-center">
            <!-- 今日收款 -->
            <div class="pricebg_demo">
              <div class="text font-colorea size18 bold">
                <p>今日</p>
                <p>收款</p>
              </div>
              <div class="sumlist acea-row">
                <div class="acea-row item" v-for="(item, index) in income.DayList" :key="index">
                  <div class="num size38 font-color55 bg-color17" :style="{ transform: `translateY(-${item * 46}px)` }" v-if="index != income.DayList.length - 3">
                    <div class="li">0</div>
                    <div class="li">1</div>
                    <div class="li">2</div>
                    <div class="li">3</div>
                    <div class="li">4</div>
                    <div class="li">5</div>
                    <div class="li">6</div>
                    <div class="li">7</div>
                    <div class="li">8</div>
                    <div class="li">9</div>
                  </div>
                  <div class="size44 font-color55" v-else>.</div>
                </div>
              </div>
              <div class="xiao size10 font-colorea">【交易{{ income.today.count_num || 0 }}笔】</div>
            </div>
            <!-- 昨日收款 -->
            <div class="month_demo acea-row row-middle">
              <div class="text font-colorea size14"><p>昨日收款</p></div>
              <div class="sumlist acea-row">
                <div class="acea-row item" v-for="(item, index) in income.YDayList" :key="index">
                  <div class="num size26 font-colorfc bg-color17" :style="{ transform: `translateY(-${item * 29}px)` }" v-if="index != income.YDayList.length - 3">
                    <div class="li">0</div>
                    <div class="li">1</div>
                    <div class="li">2</div>
                    <div class="li">3</div>
                    <div class="li">4</div>
                    <div class="li">5</div>
                    <div class="li">6</div>
                    <div class="li">7</div>
                    <div class="li">8</div>
                    <div class="li">9</div>
                  </div>
                  <div class="size28 font-color22" v-else>.</div>
                </div>
              </div>
              <div class="xiao size10 font-colorea">【交易{{ income.yesterday.count_num || 0 }}笔】</div>
            </div>
            <!-- 7日收款 -->
            <div class="month_demo acea-row row-middle">
              <div class="text font-colorea size14"><p>七日收款</p></div>
              <div class="sumlist acea-row">
                <div class="acea-row item" v-for="(item, index) in income.WeekList" :key="index">
                  <div class="num size26 font-color45 bg-color17" :style="{ transform: `translateY(-${item * 29}px)` }" v-if="index != income.WeekList.length - 3">
                    <div class="li">0</div>
                    <div class="li">1</div>
                    <div class="li">2</div>
                    <div class="li">3</div>
                    <div class="li">4</div>
                    <div class="li">5</div>
                    <div class="li">6</div>
                    <div class="li">7</div>
                    <div class="li">8</div>
                    <div class="li">9</div>
                  </div>
                  <div class="size28 font-color45" v-else>.</div>
                </div>
              </div>
              <div class="xiao size10 font-colorea">【交易{{ income.last7day.count_num || 0 }}笔】</div>
            </div>
            <!-- 本月收款 -->
            <div class="month_demo acea-row row-middle">
              <div class="text font-colorea size14"><p>本月收款</p></div>
              <div class="sumlist acea-row">
                <div class="acea-row item" v-for="(item, index) in income.MonthList" :key="index">
                  <div class="num size26 font-color09 bg-color17" :style="{ transform: `translateY(-${item * 29}px)` }" v-if="index != income.MonthList.length - 3">
                    <div class="li">0</div>
                    <div class="li">1</div>
                    <div class="li">2</div>
                    <div class="li">3</div>
                    <div class="li">4</div>
                    <div class="li">5</div>
                    <div class="li">6</div>
                    <div class="li">7</div>
                    <div class="li">8</div>
                    <div class="li">9</div>
                  </div>
                  <div class="size28 font-color09" v-else>.</div>
                </div>
              </div>
              <div class="xiao size10 font-colorea">【交易{{ income.thisMonth.count_num || 0 }}笔】</div>
            </div>
          </div>
        </div>
        <!-- 占比图 -->
        <div class="wrapper">
          <div class="data_demo"><div v-show="is_zhanbi" ref="zhanbi" style="width: 100%;height: 100%;"></div></div>
        </div>

        <div class="wrapper">
          <div class="data_demo">
            <div class="title_demo font-colorba">
              <img src="@/assets/static/iconuser.png" style="width: 21px;height: 18px;padding-right: 4px;" />
              账号审核
            </div>
            <AutoScrollList :listData="resident.check" :isPause="true" :demoType="'UserIDlist'"></AutoScrollList>
          </div>
        </div>
      </div>
      <!-- 右边盒子 -->
      <div class="right-box">
        <!-- 饼图 -->
        <div class="wrapper">
          <div class="cart_price">
            <div class="cart_title size18">停车场今日收款</div>
            <div class="acea-row row-between-wrapper" style="height: 75%;">
              <view class="acea-row row-center-wrapper" style="width: 35%;border-right: 2px dashed #fff;padding: 10px 0;box-sizing: border-box;">
                <div class="price_demo">
                  <div class="item_demo" style="padding-bottom: 6px;">
                    <p class=" font-colorf4" :class="fullscreen ? 'size28' : 'size24'">{{ parkIncome.total.toFixed(2) }}</p>
                    <p class="size14">收费总额</p>
                  </div>
                  <div class="item_demo" style="padding-bottom: 6px;">
                    <p class=" font-coloreb" :class="fullscreen ? 'size28' : 'size24'">{{ parkIncome.reduct_price.toFixed(2) }}</p>
                    <p class="size14">优惠金额</p>
                  </div>
                  <div class="item_demo">
                    <p class=" font-colorfc" :class="fullscreen ? 'size28' : 'size24'">{{ parkIncome.free_price.toFixed(2) }}</p>
                    <p class="size14">免费金额</p>
                  </div>
                </div>
              </view>
              <div ref="bindu" style="width: 65%;height: 100%;color: #fff !important;"></div>
            </div>
          </div>
        </div>
        <!-- 设备网络预警 -->
        <div class="wrapper">
          <div class="data_demo">
            <div class="title_demo font-colorba">
              <img src="@/assets/static/wl.png" style="width: 20px;height: 20px;padding-right: 4px;" />
              设备网络预警
            </div>
            <AutoScrollList :listData="parkWarn" :isPause="true" :demoType="'WarNing'"></AutoScrollList>
          </div>
        </div>
        <!-- 车辆放行处理 -->
        <div class="wrapper">
          <div class="data_demo">
            <div class="title_demo font-colorba">
              <img src="@/assets/static/cheche.png" style="width: 20px;height: 21px;padding-right: 4px;" />
              车辆放行处理
            </div>
            <AutoScrollList :listData="chelist" :isPause="true" :demoType="'ManageCart'"></AutoScrollList>
          </div>
        </div>
      </div>
    </section>
    <!-- 首次加载遮盖 -->
    <Load v-if="is_load"></Load>
    <UserDetail ref="UserDetail"></UserDetail>
  </div>
</template>

<script>
import FaTime from '@/components/Time.vue';//时间组件
import UserDetail from '@/components/UserDetail.vue';//账号审核组件
import AutoScrollList from '@/components/AutoScrollList.vue';//滚动组件
import Load from '@/components/Load.vue';//首次加载遮盖组件
import axios from 'axios';
import * as echarts from 'echarts';
export default {
  name: 'HomeView',
  components: {
    FaTime,
    AutoScrollList,
    Load,
    UserDetail
  },
  data() {
    return {
      changePieInterval: null, //饼图定时
      barindex: 1,
      barindex1: 1,
      bartext: '物业管理',
      barlist: ['门禁管理', '停车管理', '物业管理'],
      fullscreen: false, // 是否全屏
      suggest: [],
      resident: {
        check: []
      },
      repair: [],
      parkIncome: {
        total: 0,
        reduct_price: 0,
        free_price: 0
      },
      income: {
        today: {
          //今天收款
          count_num: 0
        },
        yesterday: {
          //昨天收款
          count_num: 0
        },
        last7day: {
          //一周收款
          count_num: 0
        },
        thisMonth: {
          //本月收款
          count_num: 0
        }
      },

      chelist: [],
      zhubisize: 15,
      is_zhanbi: true,
      is_load: true
    };
  },
  created() {
    this.getData();
    setInterval(() => {
      this.getData();
    }, 60000);
  },

  beforeDestroy(){
    clearInterval(this.changePieInterval);
  },
  mounted() {

  },
  methods: {
    getData() {
      axios.get('bi/Index/parkTodo').then(res=>{
        this.chelist=res.data
      })
      axios
        .get('/bi')
        .then(res => {
          let income = res.data.income;
          this.is_load = false;
          this.dataInfo = res.data;
          income.DayList = income.today.sum_hjje.split(''); //今天收款
          income.WeekList = this.chuliArr(income.last7day.sum_hjje); //一周收款
          income.YDayList = this.chuliArr(income.yesterday.sum_hjje); //昨天收款
          income.MonthList = this.chuliArr(income.thisMonth.sum_hjje); //本月收款
          this.income = income; //中间金额
          this.parkIncome = res.data.parkIncome; //饼图部分
          this.repair = res.data.repair;
          this.resident = res.data.resident;
          this.suggest = res.data.suggest; //投诉
          this.parkWarn = res.data.parkWarn; //设备网络预警
          clearInterval(this.changePieInterval);
          this.getEchartData();
          this.zhanbi();
        })
        .catch(err => {
          this.is_load = false;
        });
    },

    //字符串转数组
    chuliArr(arrList) {
      let list = arrList.split('');
      if (list.length != 11) {
        for (let i = list.length; i < 11; i++) {
          list.unshift('');
        }
      }
      return list;
    },

    // 刷新
    reload() {
      window.location.reload();
    },

    // 切换导航
    checkindex(index) {
      this.barindex = index + 1;
      this.barindex1 = index + 1;
      if (index == 1) {
        this.$router.push({
          path: '/ParkView'
        });
      } else if (index == 2) {
        this.$router.push({
          path: '/'
        });
      }
    },

    // 柱形
    zhanbi() {
      const chartDom = this.$refs.zhanbi;
      const myChart = echarts.init(chartDom);
      const option = {
        color: ['#fcfb00', '#9FE6B8', '#67E0E3', '#9FE6B8', '#FFDB5C', '#ff9f7f', '#fb7293', '#E062AE', '#E690D1', '#e7bcf3', '#9d96f5', '#8378EA', '#96BFFF'],

        //版本2
        legend: {
          top: '-2%',
          textStyle: {
            //图例文字的样式
            color: '#fff',
            fontSize: 12
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          }
        },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0%',
          top: '15%',
          containLabel: true
        },
        xAxis: [
          {
            axisLabel: {
              show: true,
              textStyle: {
                color: '#c3dbff'
              }
            },
            type: 'category',
            axisTick: {
              alignWithLabel: true
            },
            data: ['非无现金', '支付宝', '微信支付', '微信银联', '银联扣款', '托收']
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '金额',
            nameTextStyle: {
              color: '#5c84ba' //修改name的颜色
            },
            min: 0,
            max: 1000000,
            position: 'right',
            axisLabel: {
              show: true,
              textStyle: {
                color: '#5c84ba'
              },
              formatter: '{value} 元'
            }
          },
          {
            type: 'value',
            name: '占比',
            nameTextStyle: {
              color: '#5c84ba' //修改name的颜色
            },
            min: 0,
            max: 100,
            position: 'left',
            axisLabel: {
              show: true,
              textStyle: {
                color: '#5c84ba'
              },
              formatter: '{value} %'
            }
          }
        ],
        series: [
          {
            name: '占比',
            type: 'line',
            smooth: true,
            yAxisIndex: 1,
            data: [
              this.baifbFu(this.income.types.cashSum, this.income.types.allSum),
              this.baifbFu(this.income.types.aliSum, this.income.types.allSum),
              this.baifbFu(this.income.types.wechatSum, this.income.types.allSum),
              this.baifbFu(this.income.types.wechatUnionSum, this.income.types.allSum),
              this.baifbFu(this.income.types.unionDeductSum, this.income.types.allSum),
              this.baifbFu(this.income.types.bankTraySum, this.income.types.allSum)
            ]
          },
          {
            name: '金额',
            type: 'bar',
            yAxisIndex: 0,
            data: [
              this.income.types.cashSum,
              this.income.types.aliSum,
              this.income.types.wechatSum,
              this.income.types.wechatUnionSum,
              this.income.types.unionDeductSum,
              this.income.types.bankTraySum
            ]
          }
        ]
      };
      myChart.setOption(option);
      window.addEventListener('resize', function() {
        myChart.resize();
      });
    },

    // 饼图
    getEchartData() {
      const chart = this.$refs.bindu;
      const myChart = echarts.init(chart);
      const option = {
        color: ['#e19c09', '#2fe7f4', '#6924FE'],
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: 'bottom',
          textStyle: {
            //图例文字的样式
            color: '#fff',
            fontSize: 12
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: true,
            center: ['50%', '40%'],
            label: {
              show: false,
              position: 'center',
              color: '#fff'
            },
            emphasis: {
              //设置高亮时显示标签
              show: true,
              textStyle: {
                fontSize: '15',
                fontWeight: 'bold',
                color: '#fff'
              },
              label: {
                show: true,
                fontSize: '14',
                fontWeight: 'bold',
                color: '#fff'
              }
            },
            labelLine: {
              show: false,
              color: '#fff'
            },
            data: [
              {
                value: this.parkIncome.temp_total_price,
                name: '临时缴费' + this.baifbFu(this.parkIncome.temp_total_price, this.parkIncome.total) + '%'
              },
              {
                value: this.parkIncome.vip_total_price,
                name: '月卡充值' + this.baifbFu(this.parkIncome.vip_total_price, this.parkIncome.total) + '%'
              },
              {
                value: this.parkIncome.merchant_total_price,
                name: '商家充值' + this.baifbFu(this.parkIncome.merchant_total_price, this.parkIncome.total) + '%'
              }
            ]
          }
        ]
      };
      let currentIndex = -1; //当前高亮图形在饼图数据中的下标
      this.changePieInterval = setInterval(selectPie, 4000); //设置自动切换高亮图形的定时器

      function highlightPie() {
        //取消所以高亮并高亮当前图形
        //遍历饼图数据，取消所以图形的高亮效果
        for (var idx in option.series[0].data)
          myChart.dispatchAction({
            type: 'downplay',
            seriesIndex: 0,
            dataIndex: idx
          });
        //高亮当前图形
        myChart.dispatchAction({
          type: 'highlight',
          seriesIndex: 0,
          dataIndex: currentIndex
        });
      }
      myChart.on('mouseover', params => {
        //用户鼠标悬浮到某一图形时，停止自动切换并高亮鼠标悬浮的图形
        clearInterval(changePieInterval);
        currentIndex = params.dataIndex;
        highlightPie();
      });
      myChart.on('mouseout', params => {
        //用户鼠标移出时，重新开始自动切换
        if (changePieInterval) clearInterval(changePieInterval);
        changePieInterval = setInterval(selectPie, 4000);
      });
      function selectPie() {
        //高亮效果切换到下一个图形
        var dataLen = option.series[0].data.length;
        currentIndex = (currentIndex + 1) % dataLen;
        highlightPie();
      }
      myChart.setOption(option);
      window.addEventListener('resize', function() {
        myChart.resize();
      });
    },

    // 百分比
    baifbFu(val1, val2) {
      return Math.round((val1 / val2) * 100);
    },

    // 全屏事件
    handleFullScreen() {
      let element = document.documentElement;
      // 判断是否已经是全屏
      // 如果是全屏，退出
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        // 否则，进入全屏
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      // 改变当前全屏状态
      this.is_zhanbi = false;
      this.is_zhanbi = true;
      this.zhubisize = this.fullscreen ? 10 : 15;
      this.zhanbi();
      this.fullscreen = !this.fullscreen;
    }
  }
};
</script>

<style lang="scss" scoped>
.projector {
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: #030617;
  // background-image: url('@/assets/static/dbj.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center top;
  overflow: hidden;
  cursor: default;
  .header {
    position: relative;
    z-index: 9;
    height: 130px;
    width: 100vw;
    .bg {
      height: 100px;
      width: 100vw;
      background-image: url('@/assets/static/toubu.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center top;
      overflow: hidden;
    }

    .h_title {
      margin-top: 12px;
      text-align: center;
      font-size: calc(100vw * 32 / 1920);
      color: #fff;
    }

    .le_demo {
      position: absolute;
      top: 22px;
      left: 10px;
      height: 33px;
      width: 456px;
      background-image: url('@/assets/static/activity3.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      overflow: hidden;
      display: flex;
      padding: 0 20px;
      .item {
        flex: 1;
        font-size: calc(100vw * 18 / 1920);
        display: flex;
        justify-content: center;
        padding-top: 5px;
        cursor: pointer;
      }

      .activity {
        color: #fff;
        height: 31px;
        font-weight: bold;
      }
    }

    .activity1 {
      background-image: url('@/assets/static/activity1.png');
    }

    .activity2 {
      background-image: url('@/assets/static/activity2.png');
    }
    .activity3 {
      background-image: url('@/assets/static/activity3.png');
    }

    .main_demo {
      position: absolute;
      top: 86px;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 50px;
      width: 172px;
      background-image: url('@/assets/static/main.png');
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: center top;
      overflow: hidden;
      font-size: calc(100vw * 18 / 1920);
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .time_demo {
      position: absolute;
      right: 10px;
      top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      .shuax {
        padding-left: 10px;
        cursor: pointer;
      }
      .icon {
        cursor: pointer;
        padding-left: 10px;
        img {
          margin: 0;
          display: inline-block;
        }
      }
    }
  }

  .section {
    position: fixed;
    top: 0;
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
    .left-box,
    .centre-box,
    .right-box {
      flex: 1;
    }
    .left-box,
    .right-box {
      .wrapper {
        padding: 20px;
        height: 33.3%;
        color: #fff;
        .user_demo {
          position: relative;
          height: 100%;
          background-image: url('@/assets/static/lv.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          overflow: hidden;
          color: #fff;
          .centre_demo {
            position: absolute;
            top: 50%;
            left: 50%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            height: 180px;
            width: 180px;
            transform: translate(-50%, -50%);
            color: #fff;
            background-image: url('@/assets/static/shuyuan.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            overflow: hidden;
          }
          .item1,
          .item2,
          .item3,
          .item4 {
            position: absolute;
            display: inline-block;
            text-align: center;
          }
          .item1 {
            top: 22px;
            left: 50px;
            .num_demo {
              color: #ff864b;
            }
          }
          .item2 {
            top: 22px;
            right: 50px;
            .num_demo {
              color: #e34645;
            }
          }
          .item3 {
            bottom: 22px;
            left: 50px;
            .num_demo {
              color: #2fe7f4;
            }
          }
          .item4 {
            bottom: 22px;
            right: 50px;
            .num_demo {
              color: #fcfb00;
            }
          }
        }

        .data_demo {
          padding: 20px 12px;
          height: 100%;
          background-image: url('@/assets/static/xiaobj.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          overflow: hidden;
          .title_demo {
            display: flex;
            align-items: center;
          }
          .list_demo {
            .item_demo {
              margin-top: 20px;
              .time {
                padding-right: 20px;
              }
              .flex_demo {
                flex: 1;
                display: inline-block;
              }

              .address {
                margin-top: 8px;
              }
            }
          }
        }
      }

      .wrapper:nth-child(1) {
        padding-top: 72px;
      }
    }
    .centre-box {
      .wrapper {
        height: 33.3%;
        padding: 20px;
        color: #fff;

        .data_demo {
          padding: 20px 12px;
          height: 100%;
          background-image: url('@/assets/static/xiaobj.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          overflow: hidden;
          .title_demo {
            display: flex;
            align-items: center;
            vertical-align: middle;
          }
          .list_demo {
            .item_demo {
              margin-top: 20px;
              .time {
                padding-right: 20px;
              }
              .flex_demo {
                flex: 1;
                display: inline-block;
              }

              .address {
                margin-top: 8px;
              }
            }
          }
        }
      }
      .wrapper:nth-child(1) {
        display: flex;
        justify-content: center;
        padding-top: 120px;
        .pricebg_demo {
          position: relative;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: center;
          width: 95%;
          height: 65px;
          background-image: url('@/assets/static/pricebg.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          .text {
            padding: 0 8px 0 20px;
            letter-spacing: 10px;
          }
          .sumlist {
            .item {
              height: 46px;
              margin-right: 6px;
              overflow: hidden;
              .num {
                display: inline-block;
                height: 46px;
                transition: all 3s ease-out;
                .li {
                  line-height: 46px;
                  height: 46px;
                  background-color: #030617;
                  padding: 0 3px;
                }
              }
              .num:nth-child(8) {
                margin-right: 0;
              }
            }
          }
        }

        .month_demo {
          position: relative;
          padding-right: 12px;
          .text {
            width: 60px;
          }
          .sumlist {
            flex: 1;
            .item {
              height: 29px;
              margin-right: 6px;
              overflow: hidden;
              .num {
                display: inline-block;
                transition: all 3s ease-out;
                .li {
                  line-height: 29px;
                  height: 29px;
                  padding: 0 3px;
                  border: 1px solid #122344;
                }
              }
              .num:nth-child(8) {
                margin-right: 0;
              }
            }
          }
        }

        .xiao {
          width: 110px;
        }
      }
    }
    .right-box {
      .wrapper {
        .cart_price {
          height: 100%;
          background-image: url('@/assets/static/ribg.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          overflow: hidden;
          .cart_title {
            padding: 12px 0 12px;
            text-align: center;
          }
          .price_demo {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }
}
</style>
