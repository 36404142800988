<template>
  <div class="Load">
    <img src="@/assets/static/logo.png" style="width: 100px; height='80px';" />
    <view class="font-color66 acea-row row-middle" style="margin-top: 6px;">
      <img class="jiaz" src="@/assets/static/jiaz1.png" /><span style="padding-left: 6px;">加载中</span>
    </view>
  </div>
</template>

<script></script>

<style lang="scss" scoped>
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  .jiaz {
    width: 15px;
    height: 15px;
    animation: spin 2s linear infinite;
  }

  .Load {
    background-image: url('@/assets/static/dbj.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center top;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    // background-color: #fff;
    // background-color: #030617;
    // background-color: #0f2054;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
</style>
