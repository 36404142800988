<template>
  <div class="list_demo">
    <div class="item_demo acea-row size18" v-for="(item, index) in listData" :key="index">
      <div class="time" v-if="item.car_num">{{ item.car_num }}</div>
      <div class="flex_demo acea-row row-column line1">
        <div class="content line1" v-if="item.park">{{ item.park.name }}</div>
        <div class="address size14 font-colorba line1" v-if="item.park">{{ item.park.address }}</div>
      </div>
      <view class="status">
        <p class="font-color41" v-if="item.open_gate_type_text">【{{ item.open_gate_type_text }}】</p>
      </view>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listData: {
      type: Array
    },
    data() {
      return {};
    }
  }
};
</script>

<style scoped lang="scss">
.list_demo {
  .item_demo {
    margin-top: 20px;
    cursor: pointer;
    .time {
      padding-right: 20px;
    }
    .flex_demo {
      flex: 1;
      display: inline-block;
    }

    .address {
      margin-top: 8px;
    }
  }
}
</style>
