<template>
  <div class="hello">
   <div v-if="time.year">
     {{ time.year }}-{{ time.month }}-{{ time.day }}
     <span style="padding-left: 3px;width: 70px;display: inline-block;">{{ time.hour }}:{{ time.minute }}:{{ time.second }}</span>
     {{ time.week }}
   </div>
  </div>
</template>

<script>
import utils from '@/utils/utils';
export default {
  name: 'FaTime',
  props: {},
  data() {
    return {
      timeout: null,
      time: {
        year:'',
        month:'',
        day:'',
        hour:'',
        minute:'',
        second:'',
        week:'',
      }
    };
  },
  mounted() {
    utils.FormatTime();
    this.timeout = setInterval(() => {
      this.time = utils.FormatTime();
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timeout);
  }
};
</script>

<style lang="scss">
.hello {
  color: #fff;
}
</style>
