<template>
  <div class="list_demo">
    <div class="item_demo acea-row size18" v-for="(item, index) in listData" :key="index">
      <div class="time acea-row row-top font-colorba">
        <p class="acea-row row-middle">
          <img src="@/assets/static/tiem_le.png" style="width: 6px;height: 16px;" />
          {{ item.addtime.slice(0,10) }}
          <img src="@/assets/static/tiem_ri.png" style="width: 6px;height: 16px;" />
        </p>
      </div>
      <div class="flex_demo acea-row row-column line1">
        <div class="content line1">{{ item.directions ||'未填写维修内容'}}</div>
        <div class="address size14 font-colorba line1">{{ item.address }}</div>
      </div>
      <div class="status">
        <p class="font-color41">【待处理】</p>
        <!-- <p class="font-color09" v-if="item.status == 1">【待接单】</p> -->
        <!-- <p class="font-colorf4" v-if="item.status == 2">【已完成】</p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listData: {
      type: Array
    },
    data() {
      return {};
    }
  }
};
</script>

<style scoped lang="scss">
.list_demo {
  .item_demo {
    margin-top: 20px;
    cursor: pointer;
    .time {
      padding-right: 20px;
    }
    .flex_demo {
      flex: 1;
      display: inline-block;
      width: 200px;
    }

    .address {
      margin-top: 8px;
    }
  }
}
</style>
